// Footer.tsx
import React from "react";
import { Container, Box, Typography, Link, Grid } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        p: 2,
        mt: 5,
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Box mt={1}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"© "}
            {new Date().getFullYear()}
            {" Apps Can Fly, LLC   |  "}
            <Link
              href="mailto:support@appscanfly.com"
              variant="body2"
              color="text.secondary"
            >
              support@appscanfly.com
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
