// src/components/Features.tsx
import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Button,
  CardActions,
} from "@mui/material";
import RedText from "./RedText";

const apps = [
  {
    name: "KidTend",
    description: "A simplified way to check in your kids at Church",
    link: "https://kidtend.com/",
    image: "https://kidtend.com/static/media/preview.981e1fc4e22cadafda56.png",
  },
  {
    name: "Associalize",
    description: "An App for Connecting users with their associations",
    link: "https://associalize.com/",
    image:
      "https://associalize.com/static/media/preview.d1c976e7afaca9a8ccd7.png",
  },
  {
    name: "Slideable",
    description: "Daily puzzle games with a sprinkle of Physics",
    link: "https://slideable.app/",
    image:
      "https://play-lh.googleusercontent.com/p1nedwC6cUU-fCy2QiaZeMsKPijPExIUHFMauZh6b9wQXTL890xK68LferPHhmVpPpQ=w1052-h592-rw",
  },
  {
    name: "Kids of Mine",
    description: "Record important information about your Kids",
    link: "https://kidsofmine.app/",
    image: "https://kidsofmine.app/static/media/preview.22dfe98d.png",
  },
];
const Consultation: React.FC = () => {
  return (
    <Container sx={{ mt: 5 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", mb: 10 }}
      >
        Need an <RedText>App</RedText> or a <RedText>Website</RedText>?
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          href="mailto:support@appscanfly.com"
        >
          Contact Us
        </Button>
      </Grid>
    </Container>
  );
};

export default Consultation;
