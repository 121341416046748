// src/components/Features.tsx
import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Button,
  CardActions,
} from "@mui/material";
import RedText from "./RedText";

const apps = [
  {
    name: "KidTend",
    description: "A simplified way to check in your kids at Church",
    link: "https://kidtend.com/",
    image: "https://kidtend.com/static/media/preview.981e1fc4e22cadafda56.png",
  },
  {
    name: "Associalize",
    description: "An App for Connecting users with their associations",
    link: "https://associalize.com/",
    image:
      "https://associalize.com/static/media/preview.d1c976e7afaca9a8ccd7.png",
  },
  {
    name: "Slideable",
    description: "Daily puzzle games with a sprinkle of Physics",
    link: "https://slideable.app/",
    image:
      "https://play-lh.googleusercontent.com/p1nedwC6cUU-fCy2QiaZeMsKPijPExIUHFMauZh6b9wQXTL890xK68LferPHhmVpPpQ=w1052-h592-rw",
  },
  {
    name: "Kids of Mine",
    description: "Record important information about your Kids",
    link: "https://kidsofmine.app/",
    image: "https://kidsofmine.app/static/media/preview.22dfe98d.png",
  },
];
const Features: React.FC = () => {
  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
        <RedText>Apps</RedText> We've Made
      </Typography>
      <Grid container spacing={4} justifyContent="space-between">
        {apps.map((o) => (
          <Grid item xs={12} md={4}>
            <Card
              sx={{ background: "#eee", p: 1, cursor: "pointer" }}
              onClick={() => {
                window.open(o.link, "_blank");
              }}
            >
              <CardMedia sx={{ height: 320 }} image={o.image} title={o.name} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {o.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ height: 30 }}
                >
                  {o.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={o.link} target="_blank">
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
