// src/App.tsx
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/Header";
import Features from "./components/Features";
import About from "./components/About";
import Contact from "./components/Contact";
import theme from "./theme";
import Footer from "./components/Footer";
import Consultation from "./components/Consultation";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Consultation />

      <Features />
      <Footer />
      {/* <About />
      <Contact /> */}
    </ThemeProvider>
  );
};

export default App;
