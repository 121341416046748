// src/components/Header.tsx
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = false; // useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const menuItems = (
    <List>
      {["Contact"].map((text) => (
        <ListItem button key={text}>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div>
      <AppBar position="sticky" sx={{ background: "white", color: "black" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="logo">
            <img
              src={require("../images/logo.png")}
              alt="logo"
              style={{ height: 40 }}
            />
          </IconButton>
          <Typography
            variant="h6"
            style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
          >
            Apps Can <span style={{ color: "#d92528" }}>Fly</span>
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {menuItems}
              </Drawer>
            </>
          ) : (
            <>
              {/* <Button color="inherit">Home</Button>
              <Button color="inherit">Features</Button>
              <Button color="inherit">About</Button> */}
              <Button color="inherit" href="mailto:support@appscanfly.com">
                Contact
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
